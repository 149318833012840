<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="primary" style="color: white">
      {{ headerText }}
      <template v-slot:actions>
        <v-icon color="white"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'app-expansion-panel',
  props: {
    headerText: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-menu-form',{attrs:{"mode":_vm.mode,"headers":_vm.auditPlanItemHeader,"serverItems":_vm.auditPlanItemItems,"clientItems":_vm.auditPlanItemItems,"loading":_vm.loading,"serverItemsLength":_vm.auditPlanItemServerItemsLength,"isSelectable":false,"hide-edit":_vm.hideEdit,"hide-delete":_vm.hideDelete,"events":_vm.eventHandlers},scopedSlots:_vm._u([{key:"top",fn:function({ selected }){return [_c('app-menu-form-top',{attrs:{"mode":_vm.mode,"title":_vm.$t('menu.auditPlan'),"dialog":_vm.dialogAuditPlanItem,"selectedLength":selected.length,"onDelete":_vm.onDeleteAuditTaskItemItem,"dialogMaxWidth":"1500px"},on:{"show-dialog":function($event){_vm.dialogAuditPlanItem = $event}},scopedSlots:_vm._u([{key:"dialogForm",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-4"},[_c('v-toolbar',{attrs:{"dense":"","color":"primary white--text"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('menu.suggestionAuditProgram'))+" ")])],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"company","type":"select-server","label":_vm.$t('fields.selectCompany'),"binds":{
                    apiUrl: 'contact/contact/?state=approved&active=true'
                  },"view":_vm.view},model:{value:(_vm.editedAuditPlan.contact_id),callback:function ($$v) {_vm.$set(_vm.editedAuditPlan, "contact_id", $$v)},expression:"editedAuditPlan.contact_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"suggestType","type":"select","label":_vm.$t('fields.suggestType'),"binds":{
                    apiUrl: 'doc-template/document/?active=true'
                  },"items":[
                    {
                      label: 'Suggest',
                      value: 'suggest'
                    },
                    {
                      label: 'Display All',
                      value: 'display_all'
                    }
                  ]},model:{value:(_vm.editedAuditPlan.suggest_type),callback:function ($$v) {_vm.$set(_vm.editedAuditPlan, "suggest_type", $$v)},expression:"editedAuditPlan.suggest_type"}})],1)],1),(
                _vm.editedAuditPlan.suggest_type !== null &&
                _vm.editedAuditPlan.suggest_type !== undefined
              )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('app-table',{attrs:{"server-side":"","app":"audit","model":"auditplanheader","headers":_vm.suggestionHeader,"serverItems":_vm.suggestionItemItems,"clientItems":_vm.suggestionItemItems,"loading":_vm.loading,"server-items-length":_vm.suggestionItemServerItemsLength},on:{"server":_vm.getSuggestion},model:{value:(_vm.selectedSuggest),callback:function ($$v) {_vm.selectedSuggest=$$v},expression:"selectedSuggest"}})],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeAuditPlanItem}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveAuditPlanItem}},[_vm._v(" Save ")])],1)],1)]},proxy:true}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }